import { graphql } from "gatsby"
import React, { useRef, useState } from "react"
// eslint-disable-next-line
import "swiper/css/bundle"
import {
  Banner,
  Cta,
  ErrorBoundary,
  FormSendMailchimp,
  Hero,
  ImageContent,
  Layout,
  MindBodyPage,
  Phase,
  Programming,
  Seo,
  Testimonials,
  TextWithTable,
} from "../../index.js"

export default function GroupTraining({ data, pageContext }) {
  let { heroTitle, heroImage, heroSubTitle } =
    data.groupTraining.blocks[0].heroBlock
  let { title, content, schedule } =
    data.groupTraining.blocks[1].textWithTableBlock
  let programData = data.groupTraining?.blocks[2]?.phaseBlock
  let PhaseData = data.groupTraining?.blocks[3]?.phaseSliderBlock?.phaseSlide
  let cta = data.groupTraining.blocks[4].ctaBlock
  let coachesBlock = data.groupTraining.blocks[5].imageContentBlock
  let technologyBlock = data.groupTraining.blocks[6].imageContentBlock
  let { blockTitle, blockContent } =
    data.groupTraining.blocks[7].repeatTextBlock
  let form = data.groupTraining.blocks[8].formBlock

  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }

  return (
    <Layout>
      <Seo
        title={data.groupTraining.seo?.title}
        description={data.groupTraining.seo?.metaDesc}
        featuredImage={
          data.groupTraining.seo?.opengraphImage?.localFile.childImageSharp
            .gatsbyImageData
        }
      />

      <Hero
        heroImage={heroImage}
        heroTitle={{ normalTitle: heroTitle, strokeTitle: heroSubTitle }}
        color="#ffcb64"
      />
      <Banner onClick={onClick} hidden={toggle} />
      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}

      <TextWithTable
        title={title}
        content={content}
        schedule={schedule}
        lang={pageContext.lang}
      />
      <Programming programData={programData} />

      <Phase phaseSlide={PhaseData} />

      <Cta cta={cta} onClick={onClick} />
      <ImageContent
        setting={{
          image: coachesBlock.mainImage[0].localFile?.childImageSharp.fluid,
          image2: coachesBlock.mainImage[1].localFile?.childImageSharp.fluid,
          image3: coachesBlock.mainImage[2].localFile?.childImageSharp.fluid,
          background: coachesBlock.backgroundColor,
          titleColor: coachesBlock.titleColor,
          textColor: coachesBlock.contentColor,
          isReverse: coachesBlock.isReverse,
          dotColor: coachesBlock.backgroundColor,
        }}
        haveButton={coachesBlock.haveButton}
        mainTitle={coachesBlock.mainTitle}
        mainContent={coachesBlock.mainContent}
        isSlider={true}
      />

      <ImageContent
        setting={{
          image: technologyBlock.mainImage[0].localFile.childImageSharp.fluid,
          image2: technologyBlock.mainImage[1].localFile.childImageSharp.fluid,
          image3: technologyBlock.mainImage[2].localFile.childImageSharp.fluid,
          background: technologyBlock.backgroundColor,
          titleColor: technologyBlock.titleColor,
          textColor: technologyBlock.contentColor,
          isReverse: technologyBlock.isReverse,
          dotColor: technologyBlock.titleColor,
        }}
        haveButton={technologyBlock.haveButton}
        mainTitle={technologyBlock.mainTitle}
        mainContent={technologyBlock.mainContent}
        isSlider={true}
      />

      <Testimonials title={blockTitle} testimonials={blockContent} />

      <FormSendMailchimp
        form={form}
        formClassName="custom-form"
        buttonClassName="custom-button"
      />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $lang: String!) {
    groupTraining: wpPage(
      slug: { eq: $slug }
      language: { slug: { eq: $lang } }
    ) {
      id
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocks {
        ... on WpAcfHeroBlock {
          heroBlock {
            heroTitle
            heroSubTitle
            heroImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WpAcfTextWithTableBlock {
          textWithTableBlock {
            title
            content
            schedule {
              nameOfClass
              classSchedule
              iconOfClass {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        ... on WpAcfPhaseBlock {
          phaseBlock {
            title
            phaseItem {
              phaseStep
              phaseDuration
              phaseHeader
              headerContent
              headerIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              phaseBottom
              bottomContent
              bottomIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        ... on WpAcfPhaseSliderBlock {
          phaseSliderBlock {
            phaseSlide {
              phaseTitle
              phaseSubTitle
              phaseImage {
                itemTitleImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                itemText
                itemImage {
                  localFile {
                    childImageSharp {
                      fluid(quality: 90, fit: COVER) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on WpAcfCtaBlock {
          ctaBlock {
            ctaTitle
            ctaStrokeTitle
            ctaButton {
              target
              title
              url
            }
          }
        }
        ... on WpAcfImageWithContentBlock {
          imageContentBlock {
            backgroundColor
            contentColor
            fieldGroupName
            haveButton
            isReverse
            lightButton
            mainContent
            mainImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            mainTitle
            titleColor
          }
        }
        ... on WpAcfRepeatTextBlock {
          repeatTextBlock {
            blockContent {
              mainContent
              subContent
            }
            blockTitle
          }
        }
        ... on WpAcfSignupFormBlock {
          formBlock {
            signupTitle
            signupSubtitle
            signupButton
          }
        }
      }
    }
  }
`
